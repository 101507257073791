import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jumping in the `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Washington_Square_Park"
      }}>{`Washington Square Park`}</a>{` fountain has become a PayPerks way of documenting milestones. The team `}<a parentName="p" {...{
        "href": "../2012/09blog//17/payperks-fountain-jump-number-2/"
      }}>{`jumped in last year (see photo)`}</a>{` to celebrate some early milestones, and the year before that too when we were just getting going.`}</p>
    <p>{`For our third team jump, and my first (since I joined the PayPerks team this past spring), we set our sights on hitting 100,000 PayPerks users as "the next big thing." We only hoped we'd reach our goal before they turned the water off for the winter.`}</p>
    <p>{`Crossing that big milestone didn't feel "real" until we started making the trek down from our office in the Flatiron District of New York City (past several lesser fountains). Along the way, we pointed out sights, making casual conversation while trying to psych each other out with how cold the fountain water was going to be in September. Truth be told: it was more than a little chilly.`}</p>
    <p>{`The size of our team had doubled since the last jump, making it a little harder to sneak in for a quick picture and dart out again. But we somehow managed to dash in, pose, and all be looking at our plucked-from-the-crowd photographer at the same time. Miracles do happen!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAYdydUJLB//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhEDQf/aAAgBAQABBQLn00qhtEvCayvf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABwQAAEDBQAAAAAAAAAAAAAAABEAARACAyExkf/aAAgBAQAGPwK3T1OFkxox/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRQWH/2gAIAQEAAT8hvBg6gB9Ih9pxQBXIaYtIo3P/2gAMAwEAAgADAAAAEDcP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EBV//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EEyP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPxDJYAIZ1NYlKkfve7FqgnwuKgBXIAEBbZyVFQKs+z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/7f61c/PayPerks_FountainJumpIII_FullTeam_XSmall.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/d00b9/PayPerks_FountainJumpIII_FullTeam_XSmall.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/fad48/PayPerks_FountainJumpIII_FullTeam_XSmall.webp 1600w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/62ed8/PayPerks_FountainJumpIII_FullTeam_XSmall.webp 1800w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/066f9/PayPerks_FountainJumpIII_FullTeam_XSmall.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/4b190/PayPerks_FountainJumpIII_FullTeam_XSmall.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/b17f8/PayPerks_FountainJumpIII_FullTeam_XSmall.jpg 1600w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/d9c39/PayPerks_FountainJumpIII_FullTeam_XSmall.jpg 1800w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fe7556906d1ddc7d0b9f1fd9b0a57d7d/b17f8/PayPerks_FountainJumpIII_FullTeam_XSmall.jpg",
            "alt": "PayPerks_FountainJumpIII_FullTeam_XSmall",
            "title": "PayPerks_FountainJumpIII_FullTeam_XSmall",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`We haven't chosen our next milestone yet, but we're hoping to have even more PayPerksians crowded into the fountain as our team continues to grow. We'll have to take turns with which unlucky few have to take the brunt of the fountain in the back—thanks Ali, Caroline, and Tim for being brave enough to go all in this time!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAF4gpudaJEv/8QAGxAAAwACAwAAAAAAAAAAAAAAAQIDABETMTL/2gAIAQEAAQUC2Mb1Nw6S7uAJoOVP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABIR/9oACAEDAQE/AZxL/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8Bpb//xAAbEAACAwEBAQAAAAAAAAAAAAAAAQIRITESE//aAAgBAQAGPwLp9VsaLRHXTPCzSMuOj//EABsQAQEBAQADAQAAAAAAAAAAAAERACExQWFx/9oACAEBAAE/IWKxzzkBkkUce5fH1ps8E/G7umOTXVU6m//aAAwDAQACAAMAAAAQrO//xAAXEQADAQAAAAAAAAAAAAAAAAAAAWGR/9oACAEDAQE/EFsKz//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIYH/2gAIAQIBAT8QW8kDt//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQWFxkf/aAAgBAQABPxCUOcw2Y186gXfEzxvFNo6xWUyximnvCQjMXsu1+uKJgDqKd5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/101e622f4adb605cb0dd4d514c2c44e4/7f61c/PayPerks_FromInside_Fountain_Small.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/101e622f4adb605cb0dd4d514c2c44e4/d00b9/PayPerks_FromInside_Fountain_Small.webp 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/101e622f4adb605cb0dd4d514c2c44e4/066f9/PayPerks_FromInside_Fountain_Small.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/101e622f4adb605cb0dd4d514c2c44e4/4b190/PayPerks_FromInside_Fountain_Small.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/101e622f4adb605cb0dd4d514c2c44e4/4b190/PayPerks_FromInside_Fountain_Small.jpg",
            "alt": "PayPerks_FromInside_Fountain_Small",
            "title": "PayPerks_FromInside_Fountain_Small",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      